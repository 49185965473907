<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Order Number</th>
                    <th class="text-right">Order Amount</th>
                    <th>Party Name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in purchase" :key="i">
                    <td> {{ item.date }}</td>
                    <td> {{ item.bill_number }}</td>
                    <td class="text-right"> {{ item.bill_amount }}</td>
                    <td> {{ item.party_name }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm"
                                @click="goToEdit(item.id)"
                                style="cursor: pointer"
                        >
                          <i class="fas fa-eye"></i>
                        </button>
                    </td>
                </tr>

                </tbody>
            </table>
            <p v-if="!purchase.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<style scoped>
.btn-challan {
    background-color: #249024 !important;
}
</style> 

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import pdfPrinter from '@/services/utils/pdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";

export default {  
    name: 'PurchaseListTable',
    data() {
        return{
            companyInfo: {},
            invoice: {},
            barcodeQuery: '',
            barcode: '',
            user: {},
            qrcode: ''
        }
    },
    props: {
        purchase: Array,
        route_name: {
            type: String,
            default: 'purchase-bill-edit'
        },
    },
    setup() {
        const {commaFormat} = figureFormatter()
        const { fetchSalesBill } = handlePurchase();
        const {fetchCompanyInfo} = handleCompany();
        const { exportToPDF } = pdfPrinter();
        const { fetchBarcode } = handleBarcode();
        const store = useStore();

        return {
            commaFormat,
            fetchSalesBill,
            exportToPDF,
            fetchCompanyInfo,
            fetchBarcode,
            store
        }
    },
    mounted() {
         this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
            this.companyInfo = res.data;
         }).catch((error) => {
            console.log(error);
         });

         this.user = this.store.state.user.user
    },
    methods: {
        goToEdit(id) {
            let dynamicKeyName = (this.route_name === 'purchase-bill-edit') ? 'purchaseId' : 'salesId'
            let param = {
              companyId: this.$route.params.companyId,
              moduleId: this.$route.params.moduleId,
              menuId: this.$route.params.menuId,
              pageId: this.$route.params.pageId,
            }
            param[dynamicKeyName] = id;

            const query = this.$route.query;
            if(this.$route.name === 'sales-order-approval'){
                query.type = 'approval';
            }

            this.$router.push({
                name: this.route_name,
                params: param,
                query: query
            })
        }
    }
}
</script>
